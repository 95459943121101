body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.max-width100 {
  max-width: 100%;
}
.full-height {
  height: 100%;
}
button.link-button {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.positioner {
  position: relative;
}

.margin-bottom-2 {
  margin-bottom: 2em;
}

@media (min-width: 992px) {
  .row-mt-3 {
    margin-top: 3em;
  }
  .margin-top-2 {
    margin-top: 2em;
  }
}

@media (max-width: 991px) {
  .margin-top-2-mobile {
    margin-top: 2em;
  }
}


/** 
 * Links 
 */
.modal a,
.container a {
  color: #000000;
  font-weight: bold;
}
/**
 * Nav bar
 */
#responsive-navbar-nav .fa-external-link {
  font-size: .65em;
  position: relative;
  top: -4px;
  left: 3px;
}
.navbar .brand-title {
  font-size: 1em;
}
.brand-title.desk {
  display: none;
}
@media (min-width: 767px) { 
  .brand-title.desk {
  display: inline-block;
  }
  .brand-title.mobile {
  display: none;
  }
}
.harn-logo {
  max-width: 100%;
  height: auto;
}
.timeline-nav {
  border-right: 1px solid white;
}
.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-dark .navbar-nav .nav-link.active {
  color: #F36F21;
  font-weight: bold;
}


@media (min-width: 992px) { 
  .navbar .brand-image {
    margin-right: 2em;
  }
  .navbar .brand-title {
    font-size: 1.2em;
  }
  #responsive-navbar-nav, 
  .brand-title {
    margin-top: 65px;
  }
}
/**
 * Page title
 */
 h1.page-title {
   margin-bottom: 1em;
 }
/********************************************************************
 * Image Modal 
 */
.modal-dialog {
  max-width: 85%;
}
.related-object:hover {
  cursor: pointer;
}
.related-column {
  display: flex; 
  flex-direction: column;
}
.related-content, 
.additional-content {
  align-self: flex-end;
  width: 100%;
}
.additional-content {
  flex-grow: 1;
}
@media (min-width: 992px) { 
  .additional-content,
  .related-content {
    padding: 0 15px;
  }
  .related-content {
    margin-top: 2em;;
  }
}
.related-column img {
  max-width: 100%;
  width: 100%;
}

/* title area */
.modal-dialog .modal-title {
  width: 100%;
}
.modal-dialog .image-title-period {
  float: right;
  font-size: .6em;
  display: block;
  margin-right: 2em;
}
/* body area */

.modal-dialog .image-details {
  font-size: 1.4em;
  margin-top: 1em;
}
.modal-dialog .image-description {
  margin-top: 1em;
}
@media (min-width: 992px) { 
  .modal-dialog .image-description{
    margin-top: 4em;
  }
}
.modal-dialog h4 {
  margin-top: 1em;
  font-size: 2em;
}
@media (min-width: 992px) { 
  .modal-dialog h4 {
    margin-top: 0;
  }
}
/* Additional Content Component */
.additional-image-details {
  margin: 2em 0;
}

/*****************************************************************
 * Home Page
 */
 .Home {
   margin-bottom: 7em;
 }
 .Home .carousel {
  max-width: 400px;
  margin: 0 auto;
}
.exhibition-credit {
  max-width: 400px;
  text-align: center;
  margin: 2em auto;
}
button.info-button {
  position: absolute;
  bottom: 0;
  left: 1em;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid #ffffff;
  border-radius: .25em;
  padding: .25em .75em !important;
  margin-bottom: .5em;
}

/**
 * Gallery Page
*/
.gallery-image {
  margin-bottom: 1em;
  position: relative;
}
.gallery-image button {
  border: none;
  background: none;
}
.gallery-image img {
  width: 100%;
}
.additional-content-title {
  font-size: 1.1em;
  font-weight: bold;
  margin: .5em 0 0 0;
  text-align: right;
}
.additional-content .related-object {
  position: relative;
}
.additional-content .related-object button {
  width: 100%;
}
.gallery-image img, 
.gallery-image .fa-info, 
.related-column .related-object img,
.related-column .related-object .fa-info {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -ms-transition: all .5s ease;
  transition: all .5s ease;
}
.gallery-image:hover img, 
.related-column .related-object:hover img {
  cursor: pointer;
  -webkit-filter: brightness(45%);
  filter: brightness(45%);
}
.gallery-image .fa-info,
.related-column .related-object .fa-info {
  position: absolute;
  display: none;
  color: #000000;
}
.gallery-image:hover .fa-info, 
.related-column .related-object:hover .fa-info {
  display: block;
  bottom: 0;
  font-size: 3em;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
}
.related-image-logo {
  max-width: 100%;
}
.obscure.modal-body {
  background: rgba(0,0,0,.7);
}
.obscure.modal-body img {
  filter: brightness(30%);
  background: rgb(0, 0, 0);
  color-adjust: initial;
}
/*****************************************************************
 * Conservation Page
 */
.conservation-modal {
  max-width: 600px;
}
/*****************************************************************
 * Preservation Treatment Page
 */
.preservation-treatment-piece-info {
  border: 1px solid #000000;
  padding: 1em;
  margin-top: 3em;
  margin-bottom: 2em;
}
iframe {
  border: none;
  width: 100%;
  height: auto;
}
.falcons-iframe {
  height: 370px;
}
.folk-dancers-iframe {
  height: 350px;
}
.post-pres-img {
  margin-bottom: 1em;
}
.preservation-pdf {
  margin-top: 1em;
}
.post-preservation-credits {
  border: 1px solid black;
  padding: .5em;
  margin-top: 1em;
  text-align: right;
}
@media(min-width: 992px) {
  .post-preservation-credits {
    margin-top: 0;
  }
}
.post-preservation-other-images {
  border: 1px solid black;
  padding: 2em;
}

/** Additiona Content: Scanning the Guardian **/
.ct-scans-container {
  position: relative;
  height: 100%;
}
.ct-scans-details {
  text-align: center;
  font-size: 1.1em;
}
.ct-scans-directions {
  position: absolute;
  font-size: .8em;
  bottom: 10px;
  width: 100%;
}
.ct-scans-directions .right {
  text-align: right;
}
.scanning-iframe {
  min-height: 300px;
}