.ps-timeline-sec-mobile {
  display: none;
}
.small-text {
  display: inline;
  font-size: 13px;
}
.ps-timeline-sec {
    position: relative;
    top: 75px;
  }
  .ps-timeline-sec .container {
    position: relative;
  }
  @media screen and (max-width: 500px) {
    .ps-timeline-sec .container ol.ps-timeline li {
      height: 180px;
    }
  }
  @media screen and (max-width: 767px) {
    .ps-timeline-sec {
      display: none;
    }
    .ps-timeline-sec-mobile {
      display: block;
    }
    .Galleries h1.page-title {
      margin-bottom: .5em;
    }
    .ps-timeline-sec .container ol.ps-timeline {
      margin: 130px 0 !important;
      padding-left: 0 !important;
      padding-top: 0px !important; /* fix spacing */
      border-top: 0 !important;
      margin-left: 25px !important;
      margin-top: 0 !important; /* fix spacing */
    }
    .ps-timeline-sec .container ol.ps-timeline li {
      height: 200px;
      float: none !important;
      width: inherit !important;
    }
    .ps-timeline-sec .container ol.ps-timeline .img-handler-bot img {
      width: 80% !important;
    }
    /* .ps-timeline-sec .container ol.ps-timeline li:last-child {
      margin: 0;
      bottom: -65px !important;
      height: 120px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
      bottom: 40px !important;
      margin-top: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
      width: 100%;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
      margin-bottom: 0 !important;
      top: 20px;
      width: 50% !important;
    } */
    .ps-timeline-sec .container ol.ps-timeline li span {
      left: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler{
      position: absolute !important;
      bottom: 85px !important;
      width: 100% !important;
      float: left !important;
      margin-left: 15px !important;
      margin-bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler a {
        display: inline-block;
        width: 45%;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler > div{
        vertical-align: top;
        display: inline-block;
        width: 50%;
    }

    .ps-timeline-sec .container ol.ps-timeline li .img-handler img {
      margin: 0 auto !important;
      width: 60% !important;
    }
    /* .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
      position: absolute !important;
      bottom: 65px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    } */
    .ps-timeline-sec .container ol.ps-timeline li p {
      text-align: left !important;
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
    .ps-sp-top,
    .ps-sp-bot {
      display: none;
    }
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 330px 0;
    padding: 0;
    border-top: 2px solid #f36f21;
    list-style: none;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    float: left;
    width: 16%;
    padding-top: 30px;
    position: relative;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    background: #fff;
    border: 4px solid #f36f21;
    border-radius: 50%;
    box-shadow: 0 0 0 0px #fff;
    text-align: center;
    line-height: 50px -10;
    color: #df8625;
    font-size: 2em;
    font-style: normal;
    position: absolute;
    top: -26px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: '';
    color: #f36f21;
    width: 2px;
    height: 30px;
    background: #f36f21;
    position: absolute;
    top: -30px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: '';
    color: #f36f21;
    width: 8px;
    height: 8px;
    background: #f36f21;
    position: absolute;
    bottom: 65px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: '';
    color: #f36f21;
    width: 2px;
    height: 30px;
    background: #f36f21;
    position: absolute;
    bottom: -30px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: '';
    color: #f36f21;
    width: 8px;
    height: 8px;
    background: #f36f21;
    position: absolute;
    top: 65px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 95px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    display: table;

    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute;
    margin-top: 35px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
    display: table;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    position: absolute;
    margin-top: 35px;
  }
 
/** Styles **/
.gallery-image {
  max-width: 100%;
}
.gallery-period-description {
  border: 2px solid #f36f21;
  padding: .5em .5em;
  border-radius: .5em;
  display: block;
  margin: 10px auto 0;
  width: 100%;
  text-align: center;
  max-width: 150px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
      bottom: 120px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:nth-of-type(5) .img-handler-bot {
      bottom: 135px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:nth-of-type(6) .img-handler-top{
      bottom: 210px !important
    }
}

@media screen and (min-width: 768px) {
  .gallery-period-description {
    min-height: 93px;
  }
}

/** Mobile **/

.ps-timeline-sec-mobile .row {
  margin-bottom: 2em;
}
.mobile-gallery-description {
  margin-right: 2em;
  width: 200px;
  border: 2px solid #f36f21;
  padding: .5em 1em;
  border-radius: .5em;
  text-align: center;
}
@media screen and (max-width: 430px) {
  .ps-timeline-sec-mobile .col-xs-6:first-of-type {
    width: 100%;
    text-align: center;
  }
  .ps-timeline-sec-mobile .desc-row {
    width: 100%;
  }
  .mobile-gallery-description {
    margin-left: 0em;
    margin-bottom: 1em;
    margin-top: 2em;
    margin-right: 0;
    width: auto;
  }
  .img-row {
    margin: 0 auto;
  }
}